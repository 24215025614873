// $(function(){
//     $('.selectpicker').selectpicker();
// });

var ofer_shown = true;
var feedback_shown = true;
var rates = {};

// var ofer_shown = false;
// var feedback_shown = false;

$('document').ready(function(){

    //POPUP's start
    // $(".popup-link").magnificPopup({
    //     type:'inline',
    //     fixedContentPos: true,
    //     callbacks: {}
    // });
    $(".packages-link").magnificPopup({
        type:'inline',
        fixedContentPos: true,
        callbacks: {}
    });
    $(".feedback-link").magnificPopup({
        type:'inline',
        fixedContentPos: true,
        callbacks: {}
    });
    $(".calculator-link").magnificPopup({
        type:'inline',
        fixedContentPos: true,
        callbacks: {}
    });
    //POPUP's end

    updateRates();

    setTimeout(function () {
        showOferPopup();
    }, 15000);

    setTimeout(function () {
        showFeedbackPopup();
    }, 45000);

    // var deadline = 1526860800;
    var deadline = new Date(1526860800*1000);
    delta = 1526860800*1000 - Date.now();
    deadline.setTime(1526860800*1000);
    initializeClock('timer', deadline);

    var visitor_uid = '';

    if (typeof $.cookie('contact_id') == 'undefined' ) {
        $.cookie('visitor_uid', guid(), { expires: 364 });
        addNewContact($.cookie('visitor_uid'));
    } else if ($.cookie('contact_id') == null || $.cookie('contact_id') == 'null' || $.cookie('contact_id') == '') {
        $.cookie('visitor_uid', guid(), { expires: 364 });
        addNewContact($.cookie('visitor_uid'));
    } else if (typeof $.cookie('contact_id') != 'undefined') {
        addSiteVisit( $.cookie('contact_id'),document.location.href);
    }

    $('.amo-target').click(function () {
        addTarget($(this).attr('data-amo-target'), $.cookie('contact_id'));
    });
    // var source = getSource();
    // var host_name = new URL(source).hostname;

    // if (host_name != 'zodiaq.io' && source != '') {
    //     addSiteTransition(source, visitor_uid);
    // }

    //anchor START
    $('a.anchor[href*=\\#]').bind('click', function(e){
        if ($(window).width() < 992) {
            $('.navbar-toggler-icon').click();
        }
        var anchor = $(this);
        var targetId = anchor.attr('href').substring(1);
        if (targetId.length > 0) {
            goToAnch(targetId);
        }
        e.preventDefault();
        return false;
    });
    $('a.anchor-components[href*=\\#]').bind('click', function(e){
        if ($(window).width() < 992) {
            $('.navbar-toggler-icon').click();
        }
        var anchor = $(this);
        var targetId = anchor.attr('href').substring(1);
        goToAnch('components');
        $('#componentsCarousel').carousel(parseInt(targetId));
        e.preventDefault();
        return false;
    });
    //anchor END

    //Components control START
    $('.custom-carousel--left-nav').click(function () {
        $("#componentsCarousel").carousel('prev');
    });
    $('.custom-carousel--right-nav').click(function () {
        $("#componentsCarousel").carousel('next');
    });

    $("#componentsCarousel").swipe({
        swipeLeft:function(event, direction, distance, duration, fingerCount) {
            $("#componentsCarousel").carousel('next');
        },
        swipeRight:function (event, direction, distance, duration, fingerCount) {
            $("#componentsCarousel").carousel('prev');
        }
    });

    $("#distribution").swipe({
        swipeLeft:function(event, direction, distance, duration, fingerCount) {
            if ($(window).width() < 992) {
                $(".distr__arrow--next").click();
            }
        },
        swipeRight:function (event, direction, distance, duration, fingerCount) {
            if ($(window).width() < 992) {
                $(".distr__arrow--next").click();
            }
        }
    });

    //team-control start
    var team_height = $('.team-container').height();
    var team_height__full = $('.team-container')[0].scrollHeight;
    $('.btn-team').click(function (e) {
        e.stopPropagation();
        if ($('.team-container').hasClass('team-container--full')) {
            $('.team-container').removeClass('team-container--full');
            $('.team-container').animate({
                'height': getTeamContainerHeight()
            });
            $('.btn-team p').html($('.btn-team').attr('data-show_text'));
            $('.btn-team i').removeClass('fa-arrow-up');
            $('.btn-team i').addClass('fa-arrow-down');
        } else {
            $('.team-container').addClass('team-container--full');
            $('.team-container').animate({
                'height': $('.team-container')[0].scrollHeight
            });
            $('.btn-team p').html($('.btn-team').attr('data-hide_text'));
            $('.btn-team i').removeClass('fa-arrow-down');
            $('.btn-team i').addClass('fa-arrow-up');
        }

    });
    //team-control end


    //subscribe

    $.validator.addMethod("validate_email", function(value, element) {

        if (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
            return true;
        } else {
            return false;
        }
    }, "Please enter a valid Email.");

    $("#footSubscribe").validate({
        rules: {
            email: {
                validate_email: true
                // required: true,
                // email: true,
            },
        },
        messages: {
            email: "Please enter a valid email address"
        },
        submitHandler: function(form) {
            // do other things for a valid form
            subscribe();
        }
    });

    // $('#footSubscribe').submit(function(event){
    //     if ($('#footSubscribe')[0].checkValidity()) {
    //         event.preventDefault();
    //         subscribe();
    //     }
    // });

    $('.subscribe-box--btn').click(function (event) {
        $("#footSubscribe").validate({
            submitHandler: function(form) {
                // do other things for a valid form
                subscribe();
            }
        });
    });

    function subscribe() {
        var infoData = {};
        infoData['email'] = $('#sub_email').val();

        var contactData = {};
        contactData['contact_id'] = $.cookie('contact_id');
        contactData['email'] = infoData['email'];
        contactData['action'] = 'addcontact';

        $.ajax({
            url: "/amo/amoController.php",
            type: "POST",
            data: contactData,
            dataType: 'JSON',
            success: function (ans) {}
        });

        $.ajax({
            url: "/ajax.php",
            type: "POST",
            data: infoData,
            dataType: 'JSON',
            success: function (ans) {
                $('#footSubscribe').fadeOut(function () {
                    $('#footSubscribe').addClass('hidden-form');
                    $('.subscribe-suc-text').fadeIn(500);
                });
                return false;
            },
            error: function(response) {

            }
        });
    }
    //

    new WOW().init();

    var curr_dist_slide = 1;

    $('.roadmap-item').hover(
        function(){
            $('.roadmap-item').addClass('roadmap-item--hiiden')
            $(this).removeClass('roadmap-item--hiiden')
        },
        function(){ $('.roadmap-item').removeClass('roadmap-item--hiiden') }
    );
    $('.distr--right-nav').click(function(){
      var dist_slides = document.querySelectorAll('.distr-item');
      if (curr_dist_slide == 1) {
          curr_dist_slide = 2;
          dist_slides[0].style.order = '3';
          dist_slides[1].style.order = '1';
          dist_slides[2].style.order = '2';
          toogleClass(dist_slides[0], 'distr-item--active');
          toogleClass(dist_slides[1], 'distr-item--active');
      } else if (curr_dist_slide == 2) {
          curr_dist_slide = 3;
          dist_slides[0].style.order = '2';
          dist_slides[1].style.order = '3';
          dist_slides[2].style.order = '1';
          toogleClass(dist_slides[1], 'distr-item--active');
          toogleClass(dist_slides[2], 'distr-item--active');
      } else {
          curr_dist_slide = 1;
          dist_slides[0].style.order = '1';
          dist_slides[1].style.order = '2';
          dist_slides[2].style.order = '3';
          toogleClass(dist_slides[2], 'distr-item--active');
          toogleClass(dist_slides[0], 'distr-item--active');
      }

    });

    $('#y-1').on('click',function(){
        $('.y-info').fadeOut(function () {
            $('.roadmap-years-item').removeClass('roadmap-years-item--selected');
            $('#y-1').addClass('roadmap-years-item--selected');
            $('#y-1-info').css("display", "flex")
                .hide()
                .fadeIn();
        });
    });

    $('#y-2').on('click', function(){
        $('.y-info').fadeOut(function () {
            $('.roadmap-years-item').removeClass('roadmap-years-item--selected');
            $('#y-2').addClass('roadmap-years-item--selected');
            $('#y-2-info').css("display", "flex")
                .hide()
                .fadeIn();
        });
    });

    $('#y-3').on('click', function(){
        $('.roadmap-years-item').removeClass('roadmap-years-item--selected');
        $('#y-3').addClass('roadmap-years-item--selected');
        $('.y-info').fadeOut(function () {
            $('#y-3-info').css("display", "flex")
                .hide()
                .fadeIn();
        });
    });

    // $('#y-4').click(function(){
    //     $('.roadmap-years-item').removeClass('roadmap-years-item--selected');
    //     $('#y-4').parent().addClass('roadmap-years-item--selected');
    //     $('.y-info').fadeOut(function () {
    //         $('#y-4-info').css("display", "flex")
    //             .hide()
    //             .fadeIn();
    //     });
    // });

    $('.distr--left-nav').click(function(){
        var dist_slides = document.querySelectorAll('.distr-item');
        if (curr_dist_slide == 1) {
            curr_dist_slide = 3;
            dist_slides[0].style.order = '2';
            dist_slides[1].style.order = '3';
            dist_slides[2].style.order = '1';
            toogleClass(dist_slides[0], 'distr-item--active');
            toogleClass(dist_slides[2], 'distr-item--active');
        } else if (curr_dist_slide == 2) {
            curr_dist_slide = 1;
            dist_slides[0].style.order = '1';
            dist_slides[1].style.order = '2';
            dist_slides[2].style.order = '3';
            toogleClass(dist_slides[0], 'distr-item--active');
            toogleClass(dist_slides[1], 'distr-item--active');
        } else {
            curr_dist_slide = 2;
            dist_slides[0].style.order = '3';
            dist_slides[1].style.order = '1';
            dist_slides[2].style.order = '2';
            toogleClass(dist_slides[2], 'distr-item--active');
            toogleClass(dist_slides[1], 'distr-item--active');
        }

    });

    document.addEventListener("scroll", onScroll);
    $(".youtube-link").grtyoutube();
    postsCarousel();
    $(window).resize(postsCarousel);
    $('.language-selector__selected-lang').on('click', function () {
        var className = 'language-selector--open';
        var el = document.querySelector('.language-selector');
        toogleClass(el,className);
    });

    var langItems = document.querySelectorAll('.language-list__item');

    for (var i = 0; i < langItems.length; i++) {
        langItems[i].addEventListener( "click" , function(event) {
            if (event.target.tagName != 'IMG') {
                document.querySelector('.language-label').innerHTML = event.target.innerHTML.split('">')[1];
                // var className = 'language-selector--open';
                // var el = document.querySelector('.language-selector');
                // toogleClass(el,className);
                $.cookie('lang', event.target.innerHTML.split('">')[1], { expires: 364 });
                location.reload();
            }
        });
    }

    document.querySelector('.more').addEventListener('click',function () {
        if ($('.hide').css('transform') == 'matrix(0, 0, 0, 1, 0, 0)') {
            $('.hide').css('transform', 'scale(1,1)');
            $('.show').css('transform', 'scale(0,1)');
        } else {
            $('.hide').css('transform', 'scale(0,1)');
            $('.show').css('transform', 'scale(1,1)');
        }

    });
});

function postsCarousel() {
    var checkWidth = $(window).width();
    var owlPost = $('.news-carousel');
    if (typeof owlPost != 'undefined') {
        if (checkWidth < 778) {
            if (typeof owlPost.data('owl.carousel') != 'undefined') {
                owlPost.data('owl.carousel').destroy();
            }
            owlPost.removeClass('owl-carousel');
        } else if (checkWidth > 768) {
            owlPost.addClass('owl-carousel');
            owlPost.owlCarousel({
                loop: true,
                nav: true,
                dots: false,
                autoWidth: true,
                navText: ["<i class='fas fa-arrow-left news-carousel__arrow news-carousel__arrow--prev'></i>","<i class='fas fa-arrow-right news-carousel__arrow news-carousel__arrow--next'></i>"],
                responsive: {
                    0: {
                        items: 1
                    },
                    600: {
                        items: 2
                    },
                    1680: {
                        items: 3
                    }
                }
            });
        }
    }
}

function showOferPopup() {
    if (!ofer_shown) {
        var el = $('#ofer_popup');
        if (el.length) {
            $.magnificPopup.open({
                items: {
                    src: el
                },
                type: 'inline',
                removalDelay: 300,
                mainClass: 'mfp-fade'

            });
        }
        ofer_shown = true;
    }
}

function showFeedbackPopup() {
    if (!feedback_shown) {
        var el = $('#feedback_popup');
        if (el.length) {
            $.magnificPopup.open({
                items: {
                    src: el
                },
                type: 'inline',
                removalDelay: 300,
                mainClass: 'mfp-fade'
            });
        }
        feedback_shown = true;
    }
}

function getSource() {
    var source = document.referrer;
    // var host_name = new URL(source).hostname;
    // if (host_name != 'zodiaq.io') {
    //
    // }
    return source;

}

function getRefId() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("ref_id");
    return c;
}

function addNewContact(name) {
    var contact_id = '';
    var infoData = {};
    infoData['name'] = name;
    infoData['action'] = 'addlead';
    $.ajax({
        url: "/amo/amoController.php",
        type: "POST",
        data: infoData,
        dataType: 'JSON',
        success: function (ans) {
            $.cookie('contact_id', ans.contact_id, { expires: 364 });
        },
        error: function(response) {

        }
    });
}

function addSiteVisit(contact_id, url) {
    var infoData = {};
    infoData['contact_id'] = contact_id;
    infoData['url'] = url;
    infoData['action'] = 'enter';
    $.ajax({
        url: "/amo/amoController.php",
        type: "POST",
        data: infoData,
        dataType: 'JSON',
        success: function (ans) {

        },
        error: function(response) {

        }
    });
}

function addSiteTransition(url, visitor_uid) {
    var infoData = {};
    infoData['contact_id'] = contact_id;
    infoData['name'] = target;
    infoData['action'] = 'addtarget';
}

function addFeedback() {
    $('#feedback_popup_name').removeClass('form-control-danger').removeClass('form-control-success');
    $('#feedback_popup_email').removeClass('form-control-danger').removeClass('form-control-success');
    $('#feedback_popup_text').removeClass('form-control-danger').removeClass('form-control-success');

    var validName = false;
    var validEmail = false;
    var validText = false;

    var infoData = {};

    infoData['name'] = $('#feedback_popup_name').val();
    infoData['email'] = $('#feedback_popup_email').val();
    infoData['text'] = $('#feedback_popup_text').val();

    if (infoData['name'] == '') {
        $('#feedback_popup_name').addClass('form-control-danger');
        validName = false;
    } else {
        validName = true;
        $('#feedback_popup_name').addClass('form-control-success');
    }

    if (validateEmail(infoData['email'])) {
        $('#feedback_popup_email').addClass('form-control-danger');
        validEmail = false;
    } else {
        $('#feedback_popup_email').addClass('form-control-success');
        validEmail = true;
    }

    if (infoData['text'] == '') {
        $('#feedback_popup_text').addClass('form-control-danger');
        validText = false;
    } else {
        $('#feedback_popup_text').addClass('form-control-success');
        validText = true;
    }

    if (validName && validEmail && validText) {
        infoData['contact_id'] = $.cookie('contact_id');
        infoData['action'] = 'addfeedback';
        $.ajax({
            url: "/amo/amoController.php",
            type: "POST",
            data: infoData,
            dataType: 'JSON',
            success: function (ans) {
                if (ans.status == 'success') {
                    $('.feedback-popup__body').empty().append('<p>Thank you for your feedback!</p>');
                } else {
                    $('.feedback-popup__body').empty().append('<p>Internal error. Please try later</p>');
                }
            },
            error: function(response) {
                $('.feedback-popup__body').empty().append('<p>Internal error. Please try later</p>');
            }
        });
    }


}

function addTarget(target, contact_id) {
    var infoData = {};
    infoData['contact_id'] = contact_id;
    infoData['name'] = target;
    infoData['action'] = 'addtarget';
    $.ajax({
        url: "/amo/amoController.php",
        type: "POST",
        data: infoData,
        dataType: 'JSON',
        success: function (ans) {

        },
        error: function(response) {

        }
    });
}

function getTeamContainerHeight() {
    var eleToGet = $('<div id="measureTeam" class="team-container" style="display: none;">').appendTo('body');

    var height = eleToGet.css('height');

    eleToGet.remove();
    return height;
}

function getStyleRuleValue(style, selector, sheet) {
    var sheets = typeof sheet !== 'undefined' ? [sheet] : document.styleSheets;
    for (var i = 0, l = sheets.length; i < l; i++) {
        var sheet = sheets[i];
        if( !sheet.cssRules ) { continue; }
        for (var j = 0, k = sheet.cssRules.length; j < k; j++) {
            var rule = sheet.cssRules[j];
            if (rule.selectorText && rule.selectorText.split(',').indexOf(selector) !== -1) {
                return rule.style[style];
            }
        }
    }
    return null;
}

function onScroll(event) {
    var scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollOffset >= 36) {
        $('.custom-nav').addClass('custom-nav--fixed');
        //fix navbar and add styles
    } else if (scrollOffset < 36) {
        //remove styles
        $('.custom-nav').removeClass('custom-nav--fixed');
    }
    if ($('#navbar').hasClass('show')) {
        event.preventDefault();
    }

    if($(document).scrollTop()>=$('.ecosystem').position().top && !ofer_shown){
        showOferPopup();
        ofer_shown = true;
    }

    if($(document).scrollTop()>=$('#roadmap').position().top && !feedback_shown){
        showFeedbackPopup();
        feedback_shown = true;
    }
}

function toogleClass(el,className) {
    if (el.classList) {
        el.classList.toggle(className);
    } else {
        var classes = el.className.split(' ');
        var existingIndex = classes.indexOf(className);

        if (existingIndex >= 0)
            classes.splice(existingIndex, 1);
        else
            classes.push(className);

        el.className = classes.join(' ');
    }
}

function goToAnch(target) {
    var destination = $('#' + target).offset().top;
    jQuery("html:not(:animated),body:not(:animated)").animate({scrollTop: destination}, 800);
    return false;
}

function validateEmail (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(email);
}

function S4() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
}
function guid() {
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}


/* Timer */
var delta;
function getTimeRemaining(endtime) {
    var t = endtime - 1000;
    delta = t;
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {
        'total': t,
        'days': days,
        'hours': hours,
        'minutes': minutes,
        'seconds': seconds
    };
}
function initializeClock(id, endtime) {

    var daysSpan = $('.timer-element__numb.days');
    var hoursSpan = $('.timer-element__numb.hours');
    var minutesSpan = $('.timer-element__numb.minutes');
    var secondsSpan = $('.timer-element__numb.seconds');
    function updateClock() {
        var t = getTimeRemaining(delta);
        daysSpan.text(t.days);
        hoursSpan.text(('0' + t.hours).slice(-2));
        minutesSpan.text(('0' + t.minutes).slice(-2));
        secondsSpan.text(('0' + t.seconds).slice(-2));
        if (t.total <= 0) {
            setTimeout(function(){
                location.reload();
            },5000);
            clearInterval(timeinterval);
        }
    }
    updateClock();
    var timeinterval = setInterval(updateClock, 1000);
}

function updateRates() {
    $.ajax({
        url: "https://poloniex.com/public?command=returnTicker",
        type: "POST",
        data: {},
        dataType: 'JSON',
        success: function (ans) {
            rates = ans;
            console.log(rates);
        }
    });
}

function setToken() {
    var curr = $('#curr').val();
    var rate;
    var text = '';
    if (curr == 'ETH') {
        rate = 1;
    } else {
        $.each(rates, function (key,value) {
            // console.log(key);
            text = curr + '_ETH';
            console.log(text);
            if (key === text) {
                rate = value.last;
            }
        });
    }

    if ($('#curr_value').val() > 0) {
        console.log(rate);
        $('.calc-result__res').text((parseFloat($('#curr_value').val())/rate)/0.05 + ' ZDQ');
    } else {
        $('.calc-result__res').text('0 ZDQ')
    }
}